@import url("https://rsms.me/inter/inter.css");

html {
  scroll-behavior: smooth;
}

body {
  background-color: white;
  font-family: "Inter", sans-serif;
}

.navbar {
  padding: 21.5px 100px !important;
  height: 67px;
  background-color: white !important;
  border-bottom: 1px solid #f6f8f9;
}

.navbar-nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-brand img {
  height: 45px;
  width: 133px;
}

.nav-btn {
  background-color: #2a6ff1;
  color: white;
  height: 35px;
  width: 133px;
  border: none;
  border-radius: 6px;
}

.nav-item a {
  font-size: 14px;
  color: #646464;
}

/* .navbar-collapse{
    margin-top: 20px;
    border-radius: 10px;
    padding: 25px 0;
    padding-left: 10px;
    background-color: white;
    border: 1px solid #F6F8F9;
}

.navbar-collapse .nav-btn{
    margin-top: 20px;
} */

.active {
  font-weight: bold !important;
  color: #010101 !important;
}

/* Adjust for mobile view */
@media (max-width: 992px) {
  .navbar-nav {
    position: static;
    transform: none;
  }
}

.navbar-nav .nav-item {
  margin: 0 10px;
}

.hero-section {
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 157px 100px;
  padding-bottom: 90px;
  height: auto;
}

.hero-text {
  flex: 40%;
  margin-top: 30px;
}

.hero-text h1 {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 25px;
}

.hero-text p {
  color: #5e5e5e;
  font-weight: 18px;
}

.hero-text img {
  width: 180px;
  height: 52px;
  margin-top: 32px;
}

.hero-img {
  flex: 60%;
}

.hero-img img {
  height: 775px;
  width: 757px;
}

.download {
  display: flex;
  gap: 24px;
}

.download img {
  width: 180px;
  height: 52px;
}

.features {
  background: linear-gradient(to bottom, #262626, #010101);
  height: auto;
  padding: 0 125px;
  padding-top: 60px;
  padding-bottom: 272px;
}

.features h1 {
  color: white;
  font-size: 46px;
  font-weight: bold;
  max-width: 581px;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 64px;
}

.image-container {
  height: 100%;
  margin: 0;
}

.features img {
  max-height: 900px;
  max-width: 450px;
  height: 100%;
  width: 100%;
}

.card {
  width: 338px;
  height: auto;
  padding: 24px;
  border-radius: 24px !important;
}

.card:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.7s ease-in-out;
  cursor: pointer;
}

.card-title {
  display: flex;
  flex-direction: row;
  gap: 7.05px;
}

.card-title p {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.card-title img {
  width: 24px;
  height: 24px;
  margin-top: 5px;
}

.card-text {
  font-size: 14px;
  color: #5e5e5e;
}

.dark p {
  color: #bfbfbf;
}

.left-col,
.right-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38.5px;
}

footer {
  padding: 100px;
  background-color: #010101;
  color: white;
}

.footer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 37px;
}

.footer2 p {
  color: #bfbfbf;
  font-size: 16px;
  font-weight: bold;
}

.socials img {
  width: 38px;
  height: 38px;
}

.freb-contact {
  display: flex;
  flex-direction: row;
  gap: 24px;
  color: #bfbfbf;
  font-size: 16px;
}

.freb-contact p span {
  color: white;
}

.contact-info {
  padding: 0;
  width: fit-content;
  text-align: left;
}

.contact-info img {
  width: 130px;
  height: 54px;
  margin-bottom: 36px;
}

.slogan p {
  font-size: 18px;
  text-align: right;
}

.slogan1 {
  padding: 0;
  display: flex;
  justify-content: end;
}

.slogan {
  max-width: 384px;
  margin: 0;
  height: 100%;
}

.slogan p {
  font-size: 18px;
  margin-bottom: 24px;
}

.slogan img {
  height: 52px;
  width: 180px;
}

.join {
  padding-top: 179px !important;
  height: auto;
  max-height: 1500px;
}

.join h2 {
  font-size: 48px;
  margin-bottom: 24px;
}

.join p {
  font-size: 18px;
  color: #3d3d3d;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .join img {
    max-height: "30vh";
  }

  .join .container {
    margin-bottom: "30vh";
  }
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  padding: 6px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  color: #5e5e5e;
  margin-bottom: 5px;
}

.sidebar li:hover {
  background-color: #e7f5fe;
  color: #0473bd;
  padding: 6px 0;
  font-weight: bold;
}

.sidebar li.active {
  background-color: #e7f5fe;
  color: #0473bd;
  font-weight: bold;
}

.policy-container {
  display: flex;
  max-height: 100vh;
  padding-top: 20px;
  padding: 100px;
}

.sidebar {
  width: 20%;
  color: black;
  padding: 20px;
  box-sizing: border-box;
  position: sticky; /* Or use position: fixed if you want it to be fixed */
  top: 20px; /* Aligns with the top margin of the content */
  height: calc(100vh - 40px); /* Full viewport height minus padding */
  overflow-y: auto; /* Sidebar content scrollable */
}

.content {
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  display: none;
}

.section {
  margin-bottom: 50px;
}

.section h2 {
  margin-top: 0;
  font-size: 32px;
  color: #010101;
}

.section p,
.section ul,
.section ol,
.section address {
  font-size: 16px;
  line-height: 1.5;
  color: #5e5e5e;
  text-align: justify;
}

.section ul,
.section ol {
  padding-left: 20px;
}

.section ul li,
.section ol li {
  margin-bottom: 10px;
}

.page-section {
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 90px 100px;
}

.page-text {
  flex: 30%;
}

.page-text h1 {
  font-size: 64px;
  font-weight: bold;
  margin-top: 100px;
}

.page-img {
  flex: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-img img {
  height: 700px;
  width: 1000px;
}

@media (max-width: 1200px) {
  .page-img img {
    height: 600px;
    width: 600px;
  }
}

@media (max-width: 1200px) {
  .card {
    width: 310px;
    height: auto;
    padding: 20px;
    border-radius: 24px !important;
  }

  .hero-text h1 {
    font-size: 50px;
  }

  .hero-img img {
    height: 700px;
    width: 757px;
  }
}

@media (max-width: 1024px) {
  .navbar {
    padding: 21.5px 50px !important;
    height: 80px;
  }

  .join {
    max-height: 900px;
  }

  footer {
    padding: 100px 50px;
  }

  .hero-section {
    padding: 157px 50px;
  }

  .hero-text h1 {
    font-size: 50px;
  }

  .hero-img img {
    height: 630px;
    width: 757px;
  }

  .page-img img {
    height: 500px;
    width: 700px;
  }

  .page-section {
    padding: 90px 50px;
    flex-direction: column;
    text-align: center;
  }

  .page-text span {
    display: none;
  }

  .policy-container {
    padding-top: 20px;
    padding: 50px;
  }

  .page-text h1 {
    font-size: 50px;
  }

  .join h2 {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .image-container img {
    height: 800px;
    width: 400px;
    margin: 30px 0;
    margin-bottom: 50px;
  }

  .navbar-collapse.collapse {
    margin-top: 20px;
    border-radius: 10px;
    padding: 25px 0;
    padding-left: 10px;
    background-color: white;
    border: 1px solid #f6f8f9;
  }

  .nav-btn {
    margin-top: 20px;
  }

  .section h2 {
    font-size: 29px;
  }

  .navbar-toggler-icon {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 996px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-img img {
    margin-top: 80px;
    height: 630px;
    width: 607px;
  }

  .hero-text p {
    max-width: 500px;
  }

  .hero-text h1 {
    max-width: 600px;
    margin-bottom: 40px;
  }

  .features h1 {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .sidebar li .section-title {
    display: none;
  }

  .sidebar {
    width: fit-content;
    min-width: 50px;
    text-align: center;
    border: 1px solid #f6f8f9;
    border-radius: 10px;
    height: fit-content;
    padding: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
  }

  .sidebar li {
    margin: 0;
  }

  .content {
    width: 85%;
  }

  .page-section {
    padding: 0 50px;
    flex-direction: column;
    text-align: center;
    gap: 0;
    height: auto;
  }
}

@media (max-width: 767px) {
  .slogan1 {
    padding: 0;
    display: flex;
    justify-content: start;
  }

  .slogan p {
    text-align: left;
    margin-top: 50px;
    font-size: 16px;
  }

  .page-text h1 {
    font-size: 40px;
  }

  .policy-container {
    display: flex;
    max-height: 100vh;
    padding-top: 20px;
    padding: 25px;
  }

  .page-section {
    padding: 90px 50px;
    flex-direction: column;
    text-align: center;
  }

  footer {
    padding: 100px 25px;
  }

  .navbar {
    padding: 21.5px 25px !important;
  }

  .page-img img {
    height: auto;
    width: auto;
  }

  .image-container img {
    height: 550px;
    width: 300px;
  }
  .features h1 {
    font-size: 37px;
  }

  .page-img img {
    height: 400px;
    width: 500px;
  }

  .hero-section {
    padding: 157px 25px;
  }

  .footer2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-top: 37px;
    align-items: start;
  }

  .download img {
    width: 130px;
    height: 46px;
  }

  .page-section {
    padding: 0 50px;
    flex-direction: column;
    text-align: center;
    gap: 0;
    height: auto;
  }
}

@media (max-width: 767px) {
  .hero-img img {
    width: auto;
    height: auto;
  }
}

@media (max-width: 500px) {
  .section h2 {
    font-size: 24px;
  }

  .features h1 {
    font-size: 30px;
  }
}

@media (max-width: 460px) {
  .section h2 {
    font-size: 20px;
    font-weight: bold;
  }

  .features h1 {
    font-size: 30px;
  }

  .features {
    padding-right: 0;
    padding-left: 0;
  }
  .hero-section h1 {
    font-size: 40px;
  }

  .join h2 {
    font-size: 33px;
  }

  .page-img img {
    height: 25vh;
    width: 90vw;
}

  .page-section {
    padding: 90px 25px;
    padding-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .join {
    max-height: 800px;
  }
}

/* For screens smaller than 576px (typically mobile) */
@media (max-width: 576px) {
  .join {
    max-height: 700px;
  }

  .page-img img {
    height: 35vh;
    width: 85vw;
}

}
